let env

if (typeof window !== 'undefined') {
  env = {
    ...window?.__APP_STATE__?.env,
  }
} else {
  env = {
    APP_VERSION: process.env.APP_VERSION,
    API_URL: process.env.API_URL,
    APP_ENV: process.env.APP_ENV,
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
    KITCHEN_URL: process.env.KITCHEN_URL,
    MEDIA_URL: process.env.MEDIA_URL,
    RH_URL: process.env.RH_URL,
    SENTRY_DSN: process.env.SENTRY_DSN,
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
    GA_GTM_ID: process.env.GA_GTM_ID,
    POSTHOG_TOKEN: process.env.POSTHOG_TOKEN,
    POSTHOG_HOST: process.env.POSTHOG_HOST,
    LINKEDIN_CONVERSION_ID: process.env.LINKEDIN_CONVERSION_ID,
    ADWORDS_CONVERSION_ID: process.env.ADWORDS_CONVERSION_ID,
  }
}

export default env
