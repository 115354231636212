import React from 'react'
import loadable from '@loadable/component'
import LoadingScreen from 'app/theme/LoadingScreen'

const fallback = <LoadingScreen />

export default [
  {
    path: '/',
    component: loadable(() => import('./pages/HomePage'), {
      ssr: false,
      fallback,
    }),
  },
  {
    path: '/cancel/:token',
    component: loadable(() => import('./pages/CancelPage'), {
      ssr: false,
      fallback,
    }),
  },
  {
    path: '/:token',
    component: loadable(() => import('./TokenRouter'), {
      ssr: false,
      fallback,
    }),
  },
  {
    path: '/c/:catererSlug/inquiry',
    component: loadable(() => import('./pages/LeadFormPage'), {
      ssr: false,
      fallback,
    }),
  },
  {
    path: '*',
    component: loadable(() => import('./pages/NotFoundPage')),
  },
]
