import './theme/styles'

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import routes from './routes'

export default function App() {
  return (
    <Switch>
      <Redirect from='/proposal/:token' to='/:token' />
      <Redirect from='/ipv2/confirm/:token' to='/:token' />
      <Redirect from='/ipv2/:token' to='/:token' />
      <Redirect from='/confirm/:token' to='/:token' />
      <Redirect from='/:token/review' to='/:token' />
      <Redirect from='/:token/event' to='/:token' />
      {routes.map((route, key) => (
        <Route key={key} exact={true} {...route} />
      ))}

    </Switch>
  )
}
