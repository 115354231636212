import { ApiClient } from 'jsonapi-react'
import { GraphitiPlugin } from 'jsonapi-react-plugin-graphiti'
import { format, parseISO } from 'date-fns'
import env from '../env'

const getPhotoUrl = path => `${env.MEDIA_URL}/${path}`

const payableTypes = {
  'Booking': 'bookings',
  'Overage': 'overages',
}

export default function createClient(options) {
  return new ApiClient({
    ...options,
    plugins: [
      new GraphitiPlugin(),
    ],
    schema: {
      events: {
        fields: {
          start_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: date => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
          end_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: (date, attrs) => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
        },
      },
      bookings: {
        fields: {
          start_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: date => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
          end_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: (date, attrs) => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
        },
      },
      proposals: {
        relationships: {
          bookings: {
            type: 'bookings',
          },
          agreement: {
            type: 'agreements',
          },
        }
      },
      payments: {
        fields: {
          date_paid: {
            type: 'date',
          }
        },
        relationships: {
          proposal: {
            type: 'proposals',
          },
        }
      },
      leads: {
        fields: {
          caterer_id: {
            readOnly: true,
          },
          contact_id: {
            readOnly: true,
          },
          occasion_id: {
            readOnly: true,
          },
          // TODO: ENG-3606 Remove agent_id
          // We have deprecated agent_id and replaced it with experience_manager_id
          // This will remain temporarily until the third party integration 
          // with Hubspot is updated to use the experience_manager_id
          agent_id: {
            readOnly: true,
          },
          experience_manager_id: {
            readOnly: true,
          },
          cc_auth: {
            readOnly: true,
          },
          instant_book: {
            readOnly: true,
          },
          managed: {
            readOnly: true,
          },
          token: {
            readOnly: true,
          },
          created_at: {
            readOnly: true,
          },
          updated_at: {
            readOnly: true,
          },
          start_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: date => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
          end_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: (date, attrs) => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
        },
        relationships: {
          contact: {
            type: 'contacts',
          },
          occasion: {
            type: 'occasions',
          },
          proposal: {
            type: 'proposals',
          },
          caterer: {
            type: 'caterers',
          }
        }
      },
      photos: {
        fields: {
          url: {
            resolve: (val, attrs) => getPhotoUrl(attrs.path)
          },
        }
      },
      client_receipts: {
        relationships: {
          client_receipt_items: {
            type: 'client_receipt_item'
          }
        }
      },
      client_receipt_items: {
        relationships: {
          client_receipt: {
            type: 'client_receipt'
          },
          payable: {
            polymorphic: true,
            getType: attrs => {
              return payableTypes[attrs.payable_type]
            }
          },
        }
      }
    },
  })
}
